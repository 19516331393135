<template>
  <v-card>
    <v-img :src="product.image"></v-img>
    <v-card-title>{{ product.name }}</v-card-title>
    <v-card-text>
      <p>Hind: {{ product.price }} €</p>
      <v-select
        :items="product.quantities"
        v-model="selectedQuantity"
        label="Vali kogus"
        outlined
        clearable
      ></v-select>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["product", "basket"],

  computed: {
    selectedQuantity: {
      get() {
        return this.basket[this.product.id];
      },

      set(val) {
        const basket = this.basket;
        basket[this.product.id] = val;
        this.$emit("update:basket", basket);
      }
    }
  }
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>