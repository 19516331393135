<template>
  <v-app>
    <v-main class="grey lighten-3">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <Introduction />
          </v-col>
        </v-row>
        <v-row v-if="!basketConfirmed">
          <v-col v-for="product in products" :key="product.id" sm="4">
            <Product :product="product" :basket.sync="basket" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Basket
              :products="products"
              :basket.sync="basket"
              :basketConfirmed.sync="basketConfirmed"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Transport
              :basketConfirmed="basketConfirmed"
              :transportConfirmed.sync="transportConfirmed"
            ></Transport>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ContactDetails :transportConfirmed="transportConfirmed" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Introduction from "./components/Introduction";
import Product from "./components/Product";
import Basket from "./components/Basket";
import Transport from "./components/Transport";
import ContactDetails from "./components/ContactDetails";
import { products } from "./data";

export default {
  name: "App",

  components: {
    Introduction,
    Product,
    Basket,
    Transport,
    ContactDetails
  },

  data: () => ({
    products: products,
    basket: {
      pihvid: 0,
      viilud: 0,
      kaste: 0
    },
    basketConfirmed: false,
    transportConfirmed: false
  })
};
</script>
