export const products = [
  {
    id: "pihvid",
    name: "Vöneri pihvid, 200g",
    image:
      "https://lh5.googleusercontent.com/LfuzSx7paNHralJqpYikaNs0HrxD6oKgqE0bVJWceQ_t02_6gXvT3-NUIvq1UDXvRXBsMZ9E81xLyNBjM14fS7Ndmb-ShYQdZBg3fqcL-Np0PFPDVa3XZIbf935SsMfAuw=w740",
    price: 3.5,
    quantities: [2, 4, 10, 20]
  },
  {
    id: "viilud",
    name: "Vöner viilutatud, 200g",
    image:
      "https://lh5.googleusercontent.com/LW-FlRU-bPZkxtJWaFAJf-tbotIpyw0tlqFaVTW_jq5eMslSX0lF3QbyOgPNEpWVEKOWPHwnEqREThC5Im32pcklKfgiB6iyoJ8lb1kHnMX7xvlaRUYHkqMlP_z1grgBWQ=w409",
    price: 3.5,
    quantities: [2, 4, 10, 20]
  },
  {
    id: "kaste",
    name: "Kaste Mother of Sauces, 200g",
    image:
      "https://lh6.googleusercontent.com/UoE05sgUtcSXPIFbHyL3amJ0_-CaUiI-2aG07RcsdL8YhRopvlS6f--AzOeeGAw04G7fIPH4l_SOkp3lWgs6ITtvh0hufdF1jQlpVvBktmDNMcoGZ507BQg64YwuuNAdBA=w350",
    price: 3.5,
    quantities: [2, 4, 10, 20]
  }
];
