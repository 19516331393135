<template>
  <v-card>
    <v-card-title>
      <v-icon class="pr-1">mdi-truck-outline</v-icon>Tarneviis
      <v-spacer></v-spacer>
      <v-btn icon @click="showText = !showText">
        <v-icon>{{ showText ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="showText">
      <p>
        Tellimuse kohaletoomisel lisandub transporditasu vastavalt meie transpordipartneri hinnakirjale.
        Laost väljastamisel lisandub meie laopartneri väljastamistasu 2,5 € + km.
      </p>
      <v-radio-group v-model="selectTransport" :disabled="transportConfirmed">
        <v-radio label="Soovin, et tellimus tuuakse aadressile" value="too_koju"></v-radio>
        <v-text-field
          v-if="selectTransport == 'too_koju'"
          v-model="deliveryAddress"
          label="Sisestage tänav, maja- ja korterinumber ning linn"
        ></v-text-field>
        <v-radio
          label="Tulen ise järele OÜ Herbfoods lattu aadressil ..., Tallinn"
          value="tulen_lattu"
        ></v-radio>
        <v-radio label="Muu" value="other"></v-radio>
        <v-text-field v-if="selectTransport == 'other'" v-model="other"></v-text-field>
      </v-radio-group>
      <h3>Tarnekuupäev ja kellaaeg</h3>
      <p>
        Millal soovite, et tellimus kohale jõuab.
        Sisestatud aeg on informatiivne, täpse kohaletoimetamise kuupäeva ja kellaaja lepime eraldi kokku peale tellimuse kinnitamist.
      </p>
      <v-container class="pa-0">
        <v-row>
          <v-col sm="4">
            <v-menu
              v-model="datePickerMenu"
              :disabled="transportConfirmed"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="deliveryDate"
                  label="Tarne kuupäev"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="deliveryDate" @input="datePickerMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="4">
            <v-text-field
              v-model="deliveryTime"
              :readonly="transportConfirmed"
              label="Tarne kellaaeg"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="showText">
      <v-btn
        :disabled="!(selectTransport && deliveryDate && deliveryTime)"
        color="deep-purple lighten-2"
        text
        @click="toggleTransportConfirmed"
      >{{ transportConfirmed ? "Muuda" : "Kinnita" }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["transport", "basketConfirmed", "transportConfirmed"],

  data() {
    return {
      selectTransport: "",
      deliveryAddress: "",
      other: "",
      deliveryDate: "",
      deliveryTime: "",
      showText: this.basketConfirmed,
      datePickerMenu: false
    };
  },

  watch: {
    basketConfirmed(val) {
      this.showText = val;
    }
  },

  methods: {
    toggleTransportConfirmed() {
      this.$emit("update:transportConfirmed", !this.transportConfirmed);
    }
  }
};
</script>