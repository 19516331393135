<template>
  <v-card>
    <v-card-title>
      <v-icon class="pr-1">mdi-basket-outline</v-icon>Ostukorv
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-if="total"
        :headers="headers"
        :items="selectedProducts"
        dense
        hide-default-footer
      >
        <template v-slot:item.delete="{ item }">
          <v-icon v-if="!basketConfirmed" small @click="deleteItem(item)">mdi-close</v-icon>
        </template>
      </v-data-table>
      <p v-else>Ostukorv on tühi.</p>
    </v-card-text>
    <v-card-title v-if="total" class="justify-end mr-2">Kokku: {{ total }} €</v-card-title>
    <v-card-actions>
      <v-btn
        :disabled="!total"
        color="deep-purple lighten-2"
        text
        @click="toggleBasketConfirmed"
      >{{ basketConfirmed ? "Muuda" : "Kinnita" }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["products", "basket", "basketConfirmed"],

  data: () => ({
    headers: [
      { text: "Toode", value: "name" },
      { text: "Kogus", value: "quantity" },
      { text: "Hind (€)", value: "price" },
      { text: "Kokku (€)", value: "total" },
      { text: "Eemalda", value: "delete" }
    ]
  }),

  computed: {
    selectedProducts() {
      return Object.entries(this.basket)
        .filter(([, quantity]) => !!quantity)
        .map(([product_id, quantity]) => {
          const product = this.products.find(p => p.id === product_id);
          return {
            id: product_id,
            name: product.name,
            quantity: quantity,
            price: product.price,
            total: quantity * product.price
          };
        });
    },

    total() {
      return this.selectedProducts.reduce(
        (total, product) => (total += product.total),
        0
      );
    }
  },

  methods: {
    toggleBasketConfirmed() {
      this.$emit("update:basketConfirmed", !this.basketConfirmed);
    },

    deleteItem(item) {
      const basket = this.basket;
      basket[item.id] = 0;
      this.$emit("update:basket", basket);
    }
  }
};
</script>
