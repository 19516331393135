<template>
  <v-card>
    <v-card-title>
      <v-icon class="pr-1">mdi-notebook-edit-outline</v-icon>Kontaktandmed
      <!-- account-details, -edit -->
      <v-spacer></v-spacer>
      <v-btn icon @click="showText = !showText">
        <v-icon>{{ showText ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="showText">
      <p class="mb-0">Teie nimi</p>
      <v-text-field v-model="name" label="Sisestage ees- ja perenimi"></v-text-field>
      <p class="mb-0">Kontakttelefon</p>
      <v-text-field v-model="phone" label="Sisestage telefoninumber"></v-text-field>
      <p class="mb-0">Meiliaadress</p>
      <v-text-field v-model="email" label="Sisestage meiliaadress"></v-text-field>
      <p class="mb-0">Küsimused ja märkused</p>
      <v-text-field v-model="remarks"></v-text-field>
    </v-card-text>
    <v-card-actions v-if="showText">
      <v-btn
        :disabled="!(name && phone && email)"
        color="deep-purple lighten-2"
        class="mb-2 white--text"
        @click="toggleTransportConfirmed"
      >Esita tellimus</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["transportConfirmed"],

  data() {
    return {
      name: "",
      phone: "",
      email: "",
      remarks: "",
      showText: this.transportConfirmed
    };
  },

  watch: {
    transportConfirmed(val) {
      this.showText = val;
    }
  }
};
</script>